:root {
  --main-color: #f0b0d7;
  --background-color: white;
  --main-button-color: #f0b0d7;
  --main-button-color-focused: #d0e0f0;
  --monacotto-color: #2097c0;
  --monacotto-color-pale: #a7e0ff;
  --monacotto-color-almostWhite: #f3faff;
}

:focus {
  outline: none;
}

ul {
  list-style: none;
}

.App {
  font-size: 1em;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

/* ----- specific purpose ------------------------------------- */

.main {
  position: relative;
  min-height: 95vh;
  padding-bottom: 2em;
  box-sizing: border-box;
}

.mainMonacotto {
  position: relative;
  min-height: 98vh;
  padding-bottom: 2vh;
  box-sizing: border-box;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 98vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--main-button-color-focused);
}

.lpMainImage {
  width: 40em;
  margin: 2em;
}

.cardImage {
  width: 19em;
}

.cardImageLarge {
  width: 38em;
}

.mainImageContainer {
  position: relative;
}

.mainImageAbsolute {
  width: 24em;
  height: 18em;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  object-fit: cover;
}

.gate {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 25em;
  min-height: 25em;
  margin: 1em;
  padding: 0.5em;
  border: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  background-color: transparent;
  cursor: pointer;
}

.mainImage {
  width: 24em;
  height: 18em;
  object-fit: cover;
}

.howToUseMain {
  /*
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 120em; */
  margin: 1em;
  border: 3px double var(--main-color);
}

.howToUseText {
  /*
  position: absolute;
  top: 28em;
  left: 55em;
  width: 62em;
  height: 35em;
  */
  border: 3px dashed var(--main-button-color-focused);
  margin: 1em;
  padding: 1em;
}

.boxCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 20em;
  min-height: 30em;
  margin: 1em;
  padding: 0.5em;
  border: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  background-color: transparent;
  cursor: pointer;
}

.boxHistory {
  min-height: 4em;
  min-width: 80em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
  cursor: pointer;
}

.boxPermission {
  min-height: 6em;
  min-width: 30em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
  cursor: pointer;
}

.boxDetail {
  min-width: 25em;
  margin: 0.5em;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
}

/*
.cottoRolling {
  width: 5em;
  height: 5em;
  background: url('./cottoRolling.png') no-repeat;
  background-size: 40em 5em;
  animation: slide 0.5s steps(8) infinite;
}

@keyframes slide {
  to {
    background-position: -40em 0;
  }
}

.cottoStill {
  width: 5em;
  height: 5em;
  background: url('./cottoRolling.png') no-repeat;
  background-size: 40em 5em;
}
*/


/* ----- functional group ------------------------------------- */

/* +++ box +++ */

.box1 {
  position: relative;
  width: 272px;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.box2 {
  position: relative;
  width: 25em;
  min-height: 1em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
}

.box3 {
  position: relative;
  min-height: 1.5em;
  margin: 1em;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: transparent;
  cursor: pointer;
}

.box4 {
  position: relative;
  width: 24em;
  height: 10em;
  border: 1px solid var(--monacotto-color);
  border-radius: 0.5em;
  background-color: transparent;
  cursor: pointer;
}

.box2chars {
  position: relative;
  width: 2em;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.box4chars {
  position: relative;
  width: 4em;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.dummyPadButton1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  border: none;
  background-color: white;
}

/* +++ button +++ */

.button1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-color);
  cursor: pointer;
}

.button1:focus {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

.button2 {
  height: 3em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color);
  cursor: pointer;
}

.button2:focus {
  height: 3em;
  width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

.button3 {
  height: 4em;
  min-width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-color);
  cursor: pointer;
}

.button3:focus {
  height: 4em;
  min-width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

.button4 {
  height: 2em;
  width: 2em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color);
  cursor: pointer;
}

.button4:focus {
  height: 2em;
  width: 2em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

/* +++ text line +++ */

.textLine1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 50%;
  width: 96%;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

/* +++ text area +++ */

.textArea1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 90%;
  width: 96%;
  resize: none;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

/* +++ label +++ */

.label1Unfilled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
}

.label1Filled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
  font-size: 0.5em;
}

/* +++ popup +++ */

.popupBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.popupBackgroundMultiLayer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(9999 + var(--popupLayer) * 10);
}

.popup {
  position: relative;
  max-height: 90%;
/*  max-width: 85%; */
  max-width: 800px;
  z-index: 10001;
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.popupMultiLayer {
  position: relative;
  max-height: 90%;
  max-width: 85%;
  /* max-width: var(--maxWidth); */
  z-index: calc(10001 + var(--popupLayer) * 10);
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.closePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: block;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.closePopupMultiLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: calc(10000 + var(--popupLayer) * 10);
  display: block;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.topRightClose {
  position: absolute;
  top: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: #803030;
  font-size: 1em; 
}

/* +++ tooltip +++ */

.tooltip {
  position: absolute;
  left: 30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.tooltipRight {
  position: absolute;
  left: 30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.tooltipLeft {
  position: absolute;
  left: -30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.messageFade-appear-done {
  opacity: 0;
}

.messageFade-enter {
  display: flex;
  z-index: 20001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #803030;
  opacity: 1;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
}

.messageFade-enter-active {
  /*
  position: relative;
  width: 25em;
  min-height: 1em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
  */
  z-index: 20001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: #803030;
  opacity: 0;
  transition: opacity 2000ms ease-out 3000ms;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
}

.messageFade-enter-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit-done {
  display: none;
  color: #803030;
  opacity: 0;
}


/* ----- single function ------------------------------------- */

/* +++ flex +++ */

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

/* +++ margin +++ */

.margin1 {
  margin: 1em;
}

.margin2 {
  margin: 2em;
}

.marginTop1 {
  margin-top: 1em;
}

.marginTop0p5 {
  margin-top: 0.5em;
}

.marginTopBottom0p1 {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.marginTopBottom0p3 {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.marginTopBottom0p5 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.marginTopBottom1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.marginBottom0p5 {
  margin-bottom: 0.5em;
}

.marginBottom1 {
  margin-bottom: 1em;
}

.marginBottomM0p5 {
  margin-bottom: -0.5em;
}

.marginLeft1 {
  margin-left: 1em;
}

.marginSide0p5 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.marginSide1 {
  margin-left: 1em;
  margin-right: 1em;
}

/* *** padding +++ */

.padding0p5 {
  padding: 0.5em;
}

.padding1 {
  padding: 1em;
}

.paddingRight1 {
  padding-right: 1em;
}

.paddingRight5 {
  padding-right: 5em;
}

.paddingLeft1 {
  padding-left: 1em;
}

.paddingLeft5 {
  padding-left: 5em;
}

/* +++ size +++ */

.size2x2 {
  height: 2.2em;
  width: 2.2em;
}

.size1p7x1p7 {
  height: 1.7em;
  width: 1.7em;
}

.width2p4 {
  width: 2.4em;
}

.width6 {
  width: 6em;
}

.width10 {
  width: 10em;
}

.width20 {
  width: 20em;
}

.width24 {
  width: 24em;
}

.width50 {
  width: 50em;
}

.width104 {
  width: 104em;
}

.width272px {
  width: 272px;
}

.widthMax {
  width: 100%;
}

.width85PC {
  width: 85%;
}

.widthMin7 {
  min-width: 7em; 
}

.widthMin15 {
  min-width: 15em; 
}

.widthMin25 {
  min-width: 25em; 
}

.widthMax25 {
  max-width: 25em; 
}

.widthVW {
  width: 98vw;
  box-sizing: border-box;
}

.height2p2 {
  height: 2.2em;
}

.invisibleCheckBox {
  display: none;
}

.invisibleCheckBox:checked + .label1Unfilled {
  font-size: 0.5em;
}

.invisibleCheckBox:checked +.box1 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box2 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box3 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box4 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.tooltip {
  display: flex;
}

.invisibleCheckBox:checked +.tooltipRight {
  display: flex;
}

.invisibleCheckBox:checked +.tooltipLeft {
  display: flex;
}

/* +++ color +++ */

.colorRed {
  color: red;
}

.backgroundColorWhite {
  background-color: white;
}

.backgroundColorRed {
  background-color: red;
}

.backgroundColorTransparent {
  background-color: transparent;
}

/* +++ text +++ */

.font0p5 {
  font-size: 0.5em;
}

.font1p5 {
  font-size: 1.5em;
}

.font2 {
  font-size: 2em;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: Right;
}

.fontHyperLink {
  font-size: 1.3em;
  color: #8090f0;
}

/* +++ display +++ */

.invisible {
  display: none;
}

/* +++ border +++ */

.border{
  border: 1px solid green;
}

.borderNone {
  border: none;
}

.borderSelected {
  border: 2px solid var(--main-color);
}

.borderCircleMonacotto {
  border: 1px solid var(--monacotto-color);
  border-radius: 50%;
}

.borderMonacotto {
  border: 1px solid var(--monacotto-color);
  border-radius: 0.5em;
}

/* +++ cursor +++ */

.cursor {
  cursor: pointer;
}

/* +++ z-index +++ */

.z200Left {
  position: absolute;
  top: 40%;
  left: 5%;
  z-index: 200;
}

.z200Right {
  position: absolute;
  top: 40%;
  right: 5%;
  z-index: 200;
}

